import React from 'react'
import styled from 'styled-components'
import GrandientLine from './grandient-line'
import { fontStyles } from '../../../styles/variables'

const HeadingStyled = styled.h1<{ $big: boolean }>`
  max-width: 700px;
  ${(p) => (p.$big ? fontStyles.bigHeading : fontStyles.heading)}
`
const HeadingWrapper = styled.div`
  display: flex;
  max-width: 700px;
  flex-direction: column;
  gap: 2rem;
`

export const BigHeading = ({ gradient, big, text }: { gradient: boolean; big: boolean; text: string }) => {
  return gradient ? (
    <HeadingWrapper>
      <HeadingStyled $big={big}>{text}</HeadingStyled>
      <GrandientLine />
    </HeadingWrapper>
  ) : (
    <HeadingStyled $big={big}>{text}</HeadingStyled>
  )
}
